.mySwiper {
  perspective: 1200px; /* Adjust if needed */
}

.swiper-slide {
  width: 60%; /* Adjust the size of the slides */
  padding: 0 2%; /* Give some space between slides */
}

.swiper-pagination-bullet-active {
  background-color: black; /* Active pagination color */
}
/* Ejemplo de CSS básico para mejorar un <select> */
.select-custom {
  appearance: none; /* Desactiva el estilo predeterminado */
  background-color: white;
  border: 2px solid gray;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem; /* Añade padding */
  border-radius: 0.375rem; /* bordes redondeados como en Tailwind */
  background-position: right 0.5rem center;
  background-size: 12px 12px;
}
